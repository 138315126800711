/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { Spinner, BlockDownloads, Seo } from '../../components'
import './corporate-governac-styles.scss'

const CorporateGovernaceView = ({ data, loading }) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(_.get(data, 'subtitle')),
  })

  return (
    <div className="corporateGovernace__container container-fluid">
      <Seo title={`${t('seo.title')}: Corporate Governace`} />
      <div className="corporateGovernace__box">
        <div className="corporateGovernace__head">
          <h1 className="page-title col-12">{t('corporateGovernace.title')}</h1>
          <div
            className='rich-text'
            dangerouslySetInnerHTML={sanitizedData()}
          />
        </div>
        <div className="corporateGovernace__section">
          {_.map(_.get(data, 'file_list'), (item) => (
            <BlockDownloads key={item.id} data={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CorporateGovernaceView
